import { EXPERIMENT_SAVE_POST_PAGE_STYLE_ON_SITE_SAVE } from '@wix/communities-blog-experiments';
import { SITE_WAS_SAVED } from '../constants/events';
import experiments from './experiments';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context) => {
  await context.sdk.addEventListener(SITE_WAS_SAVED, async () => {
    if (experiments.isEnabled(EXPERIMENT_SAVE_POST_PAGE_STYLE_ON_SITE_SAVE)) {
      try {
        await savePostPageStyle(context);
      } catch (e) {}
    }
  });
};
