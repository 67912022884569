import monitoring from './monitoring';
import { membershipAppDefId } from '../constants/apps';

// https://bo.wix.com/wix-docs/client/client-frameworks#offline-migration
//
// To trigger the migration, the service logs in to a autopilot user and opens the editor using puppeteer.
// The editor will be open twice - one time with the last saved revision and
// one time with the last published revision (or once if they are the same).
//
// Two revisions will be modified: 1. Last saved revision 2. Last published revision
const migrateOffline = async ({ sdk, appToken }) => {
  await monitoring.toMonitored(
    'paid-post-migration.migrate-offline',
    (async () => {
      return sdk.document.tpa.add.application(appToken, {
        appDefinitionId: membershipAppDefId,
      });
    })(),
  );
};

export default { migrateOffline };
