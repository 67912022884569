import { forIn } from 'lodash';
import { patchSettings } from './settings';
import { getTextPresets, getColorPresets } from './magic-migration/style-utils';
import { blogAppDefId } from '../constants/apps';
import { POST_WIDGET_ID } from '../constants/widgets';

export const savePostPageStyle = async ({ sdk, appToken }) => {
  const [appInstance, textPresets, colorPresets] = await Promise.all([
    sdk.document.info.getAppInstance(appToken),
    sdk.theme.fonts.getMap(appToken).then(getTextPresets),
    sdk.theme.colors.getAll(appToken).then(getColorPresets),
  ]);

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);
  const component = blogAppComponents.find((_component) => _component.widgetId === POST_WIDGET_ID);
  const componentRef = await sdk.document.components.getById(appToken, { id: component.id });
  const style = await sdk.tpa.getStyleParams(appToken, { compRef: componentRef });

  const fontsByLanguage = await sdk.document.fonts.getFontsOptions(appToken);
  const allFontDisplayNames = fontsByLanguage
    .map((font) => font.fonts)
    .flat()
    .map((font) => ({ key: font.fontFamily, value: font.displayName }))
    .reduce((result, font) => {
      result[font.key] = font.value;
      return result;
    }, {});

  forIn(style.fonts, function (value, key) {
    style.fonts[key].displayName = allFontDisplayNames[value.family];
  });
  patchSettings(appInstance, componentRef.id, 'draft', { textPresets, colorPresets, style });
};
