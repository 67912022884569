import Raven from 'raven-js';
import { create } from '@wix/fedops-logger';

let fedopsInstance;

const init = (appName, ravenConfig, { metaSiteId, instanceId } = {}) => {
  Raven.config(ravenConfig, { tags: { metaSiteId, instanceId } }).install();
  fedopsInstance = create(appName);
};

const interactionStarted = (interactionName) => {
  try {
    return fedopsInstance.interactionStarted(interactionName);
  } catch (err) {
    Raven.captureException(err, { tags: { interactionName, fedopsError: 'interactionStarted' } });
  }
};

const interactionEnded = (interactionName) => {
  try {
    return fedopsInstance.interactionEnded(interactionName);
  } catch (err) {
    Raven.captureException(err, { tags: { interactionName, fedopsError: 'interactionEnded' } });
  }
};

const interactionFailed = (interactionName, err, message) => {
  console.log(`${interactionName}${message ? ': ' + message : ''}`, err);
  Raven.captureException(err, { tags: { interactionName } });
};

const reportError = Raven.captureException;

const toMonitored = async (interactionName, promise, rethrow = true) => {
  try {
    interactionStarted(interactionName);
    const response = await promise;
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err);
    if (rethrow) throw err;
  }
};

export default {
  init,
  reportError,
  interactionStarted,
  interactionEnded,
  interactionFailed,
  toMonitored,
};
